import type { ImageProps } from '@archipro-design/aria';
import type { placeholderColor } from '@archipro-design/aria';

type customPlaceholder = Pick<ImageProps, 'placeholderColor' | 'variables'>;

export const getCustomPlaceholderColor = (
    placeholderColor: placeholderColor
) => {
    const customPlaceholderColorDark = {
        placeholderBackgroundColorDark: 'rgba(0, 0, 0, 0.1)',
    };

    const customPlaceholderColorLight = {
        placeholderBackgroundColorLight: 'rgba(255 255, 255, 0.1)',
    };

    const values: customPlaceholder = {
        placeholderColor: placeholderColor,
        variables:
            placeholderColor === 'dark'
                ? customPlaceholderColorDark
                : customPlaceholderColorLight,
    };

    return values;
};
